import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export const getContactTypeText = (type) => {
    let contactType = '';

    switch (type) {
        case 1:
            contactType = 'Vendor';
            break;
        case 2:
            contactType = 'Landlord';
            break;
        case 3:
            contactType = 'Applicant / Buyer';
            break;
        case 4:
            contactType = 'Applicant / Tenant';
            break;
        case 5:
            contactType = 'Supplier';
            break;
    }

    return contactType;
};

export const dragOptions = computed(() => {
    return {
        animation: 300,
        disabled: false,
    };
});

export const getUserFullName = (userObj) =>
    userObj.surname
        ? userObj.first_name + ' ' + userObj.surname
        : userObj.first_name;

export const getUserImage = (image, type) => {
    if (image == null || image === 'undefined' || image == '') {
        return `${import.meta.env.VITE_APP_S3_BASE_URL}/static-content/${type === 'property' ? 'property-thumb.jpg' : 'default-user.png'}`;
    }
    // return `${import.meta.env.VITE_APP_S3_BASE_URL}${image}`;
    return `${image}`;
};

export const searchArray = (data, searchValue) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i][searchValue]) {
            return searchValue;
        }
    }

    return false;
};

export const getHyphenCase = (str) => str.replace(/\s/g, '-').toLowerCase();

export const getSentenceCase = (str) => {
    return str.replace(/^[a-z]|[A-Z]/g, function (v, i) {
        return i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase();
    });
};

export const getCamelCase = (str) => {
    return str
        .replace(/\s(.)/g, function (a) {
            return a.toUpperCase();
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, function (b) {
            return b.toLowerCase();
        });
};

export const getPascalCase = (title) => {
    if (!title) {
        return false;
    }

    return title
        .replace(/\b(\w)/g, function (match, capture) {
            return capture.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const isObjectEmpty = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
};

export const firstWord = (string) => {
    return string.split(' ')[0];
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const timeString12hr = (timeString) => {
    const [hourString, minute] = timeString.split(':');
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'AM' : 'PM');
};

const remainingChild = ref([]);
export const horizontalOverflowItems = (parent) => {
    remainingChild.value = [];
    if (parent) {
        for (let i = 0; i < parent.length; i++) {
            // console.log(parent[i].children.length);
            let parentWidth = parent[i].clientWidth - 48;
            //remainingChild.value = [];
            if (parent[i].children.length > 4) {
                for (let j = 0; j < parent[i].children.length; j++) {
                    let childNodes = parent[i].children[j];
                    let childNodesOffset = parent[i].children[j].offsetLeft;
                    if (childNodesOffset > parentWidth) {
                        childNodes.classList.add('!hidden');
                        remainingChild.value.push(childNodes);
                        // console.log(remainingChild.value.length);
                        if (parent[i].classList == 'timeline_contacts') {
                            parent[i].previousElementSibling.innerHTML =
                                '+' + parseInt(remainingChild.value.length - 1);
                        } else {
                            parent[i].previousElementSibling.innerHTML =
                                '+' + parseInt(remainingChild.value.length);
                        }
                        parent[i].children[0].classList.remove('!hidden');
                    }
                }
            } else {
                parent[i].previousElementSibling.innerHTML = '';
            }
        }
    }
};
export const getFirstNamesWithAmpersand = (array) => {
    // console.log(array, 'global array');
    if (array.length) {
        const firstNames = array.map((contact) => {
            if (contact.first_name) {
                return contact.first_name.split(' ')[0];
            }
        });
        const length = firstNames.length;
        if (length === 2) {
            return `${firstNames[0]} & ${firstNames[1]}`;
        } else if (length > 2) {
            return `${firstNames.slice(0, length - 1).join(', ')} & ${
                firstNames[length - 1]
            }`;
        } else {
            return firstNames[0];
        }
    }
};

export const groupEmailContact = (array) => {
    const firstContact = array[0];
    if (firstContact) {
        const email = firstContact.email;
        const phone = firstContact.phone;
        return `${email} - ${phone}`;
    }
};

export const replaceMarkTag = (name) => {
    name = name.replace(/<\/?mark>/gi, '');
    let firstChar = name.charAt(0).toUpperCase();
    return firstChar;
};

// extract numbers at the end from strings
export const extractNumbers = (string) => {
    for (const str of string) {
        const match = str.match(/\d+/);
        if (match) {
            const number = parseInt(match[0]);
            return number;
        }
    }
};

export function findParentPath(array, currentLevel, path = []) {
    // debugger;
    for (let i = 0; i < array.length; i++) {
        const item = array[i];
        // Extend the current path with this item's title
        const newPath = [...path, item.title];

        // Check if the current item is the target
        if (item.levels === currentLevel) {
            return newPath;
        }

        // Otherwise, recurse into 'subnav' if it exists
        if (item.subnav && item.subnav.length) {
            const foundPath = findParentPath(
                item.subnav,
                currentLevel,
                newPath
            );
            if (foundPath.length) {
                return foundPath; // Path found in subnav
            }
        }
    }
    return []; // Path not found
}

export function getNext15MinIntervalWithMoment() {
    // Get the current time
    let now = moment();

    // Check if the current time is before 7:00 am
    if (now.isBefore(moment('7:00 am', 'hh:mm a'))) {
        return '7:00 am';
    }

    // Calculate how many minutes to add to reach the next 15-minute interval
    let minutesToAdd = 15 - (now.minutes() % 15);
    if (minutesToAdd !== 15) {
        // If it's not exactly on a 15-minute mark, add the necessary minutes
        now.add(minutesToAdd, 'minutes');
    }

    // Format the time as hh:mm A (A for AM/PM)
    const formattedTime = now.format('hh:mm a');

    return formattedTime;
}

export function removeEmptyKeys(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null && v !== '')
    );
}

export function useThemeSwitcher() {
    const switchTheme = () => {
        if (localStorage.theme_mode === 'light') {
            localStorage.setItem('theme_mode', 'dark');
            document.documentElement.classList.add('dark');
        } else if (localStorage.theme_mode === 'dark') {
            localStorage.setItem('theme_mode', 'light');
            document.documentElement.classList.remove('dark');
        }
    };
    const showTheme = () => {
        if (localStorage.theme_mode === 'light') {
            document.documentElement.classList.remove('dark');
        } else if (localStorage.theme_mode === 'dark') {
            document.documentElement.classList.add('dark');
        }
    };
    return { switchTheme, showTheme };
}

let toRoute = ref();
let fromRoute = ref();
export const getPreviousNextRoute = () => {
    const route = useRoute();
    watch(
        () => route.path,
        (to, from) => {
            toRoute = to;
            fromRoute = from;
        }
    );
    return {
        toRoute,
        fromRoute,
    };
};
// Screen break points
export function useScreenSize() {
    const screenSize = ref('');

    // Define the screen sizes based on your definitions
    const screenSizes = {
        xs: { max: 575 },
        sm: { min: 576, max: 767 },
        md: { min: 768, max: 1023 },
        lg: { min: 1024, max: 1439 },
        xl: { min: 1440 },
    };

    // Function to check the current screen size
    const checkScreenSize = () => {
        const width = window.innerWidth;

        if (width <= screenSizes.xs.max) {
            screenSize.value = 'xs';
        } else if (width >= screenSizes.sm.min && width <= screenSizes.sm.max) {
            screenSize.value = 'sm';
        } else if (width >= screenSizes.md.min && width <= screenSizes.md.max) {
            screenSize.value = 'md';
        } else if (width >= screenSizes.lg.min && width <= screenSizes.lg.max) {
            screenSize.value = 'lg';
        } else if (width >= screenSizes.xl.min) {
            screenSize.value = 'xl';
        }
    };

    // Listen to window resize events
    onMounted(() => {
        window.addEventListener('resize', checkScreenSize);
        // Initial check when component is mounted
        checkScreenSize();
    });

    onUnmounted(() => {
        window.removeEventListener('resize', checkScreenSize);
    });

    // Return the screenSize ref and the checkScreenSize function
    return { screenSize, checkScreenSize };
}

// deep equal objects
export function deepEqualObj(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== 'object' ||
        obj1 === null ||
        typeof obj2 !== 'object' ||
        obj2 === null
    ) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqualObj(obj1[key], obj2[key]))
            return false;
    }

    return true;
}

export function getChecklistCount(view, name, value) {
    if (value) {
        const checklistItem = value[0]?.check_list_detail.find(
            (item) => name === item.title
        );
        if (checklistItem) {
            return {
                title: 'Buyer',
                total_points: checklistItem.total_count,
                obtain_points: checklistItem.check_count,
                color_inner: 'bg-purple-500',
                color_outer: view == 'list' ? 'bg-gray-100' : 'bg-white',
            };
        } else {
            return {
                title: 'Buyer',
                total_points: 7,
                obtain_points: 4,
                color_inner: 'bg-purple-500',
                color_outer: view == 'list' ? 'bg-gray-100' : 'bg-white',
            };
        }
    } else {
        return {
            title: name,
            total_points: 7,
            obtain_points: 3,
            color_inner: 'bg-purple-500',
            color_outer: view == 'list' ? 'bg-gray-100' : 'bg-white',
        };
    }
};

export function compareObjects (obj1, obj2)  {
    // Convert both objects to JSON strings and compare them
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== 'object' ||
        obj1 === null ||
        typeof obj2 !== 'object' ||
        obj2 === null
    ) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
            return false;
    }

    return true;
}


export function combineFilter (filter, globalFilter) {
    let flterObj = {};
    let globalFilterObj = {};

    try {
        if(filter && filter != {}){
            console.log('filter',filter);
            flterObj = JSON.parse(filter);
        }
    } catch (e) {
        console.error("Error parsing filters:", e);
    }

    try {
        globalFilterObj = JSON.parse(globalFilter || '{}');
    } catch (e) {
        console.error("Error parsing global filter:", e);
    }

    // Merge both filter objects into one
    const combinedFilterObj = { ...flterObj, ...globalFilterObj };
    
    return combinedFilterObj;
};
